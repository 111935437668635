import { HttpClient, HttpResponseMessage } from "aurelia-http-client";
import { NitTools } from "../classes/NursitTools";
import { DialogMessages } from "./DialogMessages";
import { DialogService } from "aurelia-dialog";
import { translations } from "../classes/translations";
import { I18N } from "aurelia-i18n";
import { UserService } from "./UserService";
import { PatientItem } from "resources/classes/Patient/PatientItem";
import * as Fhir from "../classes/FhirModules/Fhir";
import { IFormSetting } from "../classes/IFormSettings";
import { QuestionnaireService } from "./QuestionnaireService";
import { FhirService } from "./FhirService";
import { RuntimeInfo } from "../classes/RuntimeInfo";
import { App } from "app";
import { ConfigService } from "./ConfigService";
import * as moment from "moment";
import { SpiSpiderSvg } from "../classes/SpiSpiderSvg";
import { fhirEnums } from "../classes/fhir-enums";
import HTTPVerb = fhirEnums.HTTPVerb;
import { PprInfo } from "resources/elements/patient-overview/PprInfo";

export class ReportService {
    private static _reportList: IReportList = undefined;
    dialogService: DialogService;
    i18n: I18N;

    public static get Reports(): IReportList {
        return this._reportList;
    }

    public static get PreviewUrl() {
        return NitTools.IncludeTrailingSlash(this.BaseUrl) + "preview";
    }

    public static ShowReportError(dialogService, e, i18n) {
        let respText = e["responseText"];
        let message = i18n.tr("print_error");
        if (respText) {
            let errorJS = JSON.parse(respText);
            if (ConfigService.Debug) {
                console.warn("Server-Error when printing:", errorJS);
            }

            let em = errorJS["ExceptionMessage"];
            if (em) {
                message += `:<br />${em}`;
            }
        } else if (e) {
            message += `:<br />${e}`;
        }

        DialogMessages.Prompt(dialogService, message, i18n.tr("error"), true);
    }

    public static SendAutoSave(
        questionnaireResponseId: string,
        reportName: string,
        patient: PatientItem,
        bodyPart: string = undefined,
    ): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (!reportName) {
                return resolve(undefined);
            }

            let response = patient.questionnaireResponses.find(
                (o) => o.id === questionnaireResponseId,
            );

            if (response?.questionnaire) {
                const questionnaire =
                    QuestionnaireService.GetQuestionnaireDirect(
                        response.questionnaire,
                    );
                if (questionnaire && questionnaire.name) {
                    const setting =
                        ConfigService.GetFormSettingsByQuestionnaireName(
                            questionnaire.name,
                        );
                    if (setting) {
                        const result =
                            await ReportService.RequirementsFulfilled(
                                App.i18n,
                                setting,
                                patient,
                            );
                        if (!result.fulfilled) {
                            const msg =
                                result.message?.map((o) => o.text).join(", ") ||
                                "Requirements for Auto-Print not met!";
                            return reject(msg);
                        }
                    }
                }
            }

            if (ConfigService.Debug)
                console.info(`Sending AutoSave for report "${reportName}"`);

            this.PrintProx(
                "Image",
                questionnaireResponseId,
                reportName,
                patient,
                true,
                false,
                bodyPart,
            )
                .catch((e) => reject(e))
                .then((result) => resolve(result));
        });
    }

    public static async Mail(
        questionnaireResponseId: string,
        reportName: string,
        patient: PatientItem,
        bodyPart: string = undefined,
    ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.PrintProx(
                "Mail",
                questionnaireResponseId,
                reportName,
                patient,
                true,
                false,
                bodyPart,
            )
                .catch((e) => reject(e))
                .then((result) => resolve(result));
        });
    }

    public static async RequirementsFulfilled(
        i18n: I18N,
        formSetting: IFormSetting,
        patient: PatientItem,
    ): Promise<ICheckReportResult> {
        await ConfigService.LoadConfigOverride(patient?.ward, patient);
        const setting = NitTools.Clone(formSetting);
        if (setting?.route === "PPR2.0") {
            if (!patient.ppr) {
                patient.ppr = await PprInfo.Load(patient, i18n);
            }

            if (patient.ppr?.questionnaireName) {
                setting.questionnaireName = patient.ppr.questionnaireName;
                setting.report = {
                    name : setting.questionnaireName
                };
            }
        }

        let result: ICheckReportResult = {
            fulfilled: false,
            message: [],
        };

        if (!setting || !setting.report) {
            result.fulfilled = true;
            return result;
        }

        if (ConfigService.EncounterTypeChoice?.enabled) {
            const encounterChoice = PatientItem.GetEncounterChoiceItem(patient);
            if (encounterChoice?.forms) {
                const frm = encounterChoice?.forms[setting.route];
                if (frm?.questionnaireName) {
                    setting.questionnaireName = frm.questionnaireName;
                }

                if (frm?.report) {
                    for (const key in frm.report) {
                        setting.report[key] = frm.report[key];
                    }

                    /* if (frm.report.name) {
                        setting.report.name = frm.report.name;
                    }

                    if (frm.report.other) {
                        setting.report.other = frm.report.other;
                    } */
                }
            }
        }

        if (!setting?.report?.name) {
            result.message.push({
                text: "no_report_specified",
            });
        }

        let basicQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(setting.questionnaireName,);
        if (!basicQuestionnaire) {
            result.message.push({
                text: "invalid_questionnaire_specified_for_setting",
                questionnaire: setting.questionnaireName,
            });
        } else {
            if (!patient.questionnaireResponses || QuestionnaireService.GetResponsesOfType(patient, basicQuestionnaire.id).length === 0) 
            {
                result.message.push({ text: "no_responses_yet" });
            }

            if ((setting?.report?.requires?.length || 0) > 0) {
                for (let i = 0; i < setting.report.requires.length; i++) {
                    let formName = setting.report.requires[i];
                    let questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(formName);
                    if (questionnaire) {
                        let responses = <any[]>(
                            patient.questionnaireResponses.filter(
                                (o) =>
                                    o.status &&
                                    (o.status === "completed" ||
                                        o.status === "amended") &&
                                    o.questionnaire,
                            )
                        );
                        if (FhirService.FhirVersion <= 3) {
                            // in R3 we have response.questionnaire.reference
                            responses = responses.filter(
                                (o) =>
                                    // have to use indexOf instead of endsWith because the reference may be like 'Questionnaire/<id>/_history/<version>'
                                    o.questionnaire?.reference
                                        ?.toUpperCase()
                                        .indexOf(
                                            `Questionnaire/${questionnaire.id}`.toUpperCase(),
                                        ) > -1,
                            );
                        } else {
                            // in R4 we have the canonical url
                            responses = responses.filter(
                                (o) =>
                                    o.questionnaire
                                        .toUpperCase()
                                        .indexOf(
                                            "/" +
                                                questionnaire.name.toUpperCase(),
                                        ) > -1,
                            );
                        }

                        if (!responses || responses.length === 0) {
                            result.message.push({
                                text: "response_missing",
                                questionnaire: questionnaire.title,
                            });
                        }
                    } else {
                        result.message.push({
                            text: "questionnaire_missing",
                            questionnaire: formName,
                        });
                        console.warn(
                            'Print PreReqs: Questionnaire with name "' +
                                formName +
                                '" not found!',
                        );
                    }
                }
            }
        }

        if (result.message.length === 0) {
            result.fulfilled = true;
        } else {
            // translate the messages
            result.message.forEach(
                (m) =>
                    (m.text = i18n
                        .tr(m.text)
                        .replace("%QUESTIONNAIRE%", m.questionnaire)),
            );
        }

        return result;
    }

    public static ShowPdf() {
        let url = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}/Pdf/${RuntimeInfo.PrintFrame.getAttribute("data-pdf-id")}`;
        window.open(url, "_pdf");
    }

    public static async GetReportByName(reportName: string): Promise<string> {
        let reports = await ReportService.Fetch();
        if (NitTools.IsArray(reports?.items)) {
            for (let i = 0; i < reports.items.length; i++) {
                let serverSideName = reports.items[i];
                if (serverSideName) {
                    serverSideName = serverSideName
                        .toLowerCase()
                        .replace(".frx", "");

                    let localName = reportName
                        ? reportName.toLowerCase().replace(".frx", "")
                        : undefined;

                    if (serverSideName === localName) {
                        return Promise.resolve(localName);
                    }
                }
            }
        }

        return Promise.resolve(undefined);
    }

    private static _reportServer: string;

    public static get ReportServer(): string {
        return this._reportServer;
    }

    public static set ReportServer(value: string) {
        if (value) {
            // remove the old "/api" from the reportserver url in cases where the config has not been updated
            if (
                NitTools.IncludeTrailingSlash(value)
                    .toUpperCase()
                    .endsWith("/API/")
            )
                value = value.substr(0, value.length - 5);
            if (value.indexOf("*") > -1)
                value = value.replace("*", window.location.hostname);
        }

        this._reportServer = value;
    }

    public static get BaseUrl(): string {
        let server = ReportService.ReportServer;
        if (server.endsWith("/")) {
            server = server.substr(0, server.length - 1);
        }

        if (server.endsWith("/api")) {
            server = server.substr(0, server.length - 4);
        }

        return server;
    }

    public static PrintProxTEST(
        targetMethod: string,
        questionnaireResponseId: string,
        reportName: string,
        patient: PatientItem,
        isSaveOnly: boolean,
        isMultiPrint: boolean = false,
        bodyPart: string,
        woundId: string = undefined,
        beforeSend?: Function,
    ): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (!ReportService.ReportServer) return resolve(true);

            let localName = reportName;
            let reportRequestContent = {
                userName: UserService.UserName || " ",
                userLastName: UserService.UserLastName || " ",
                userFirstName: UserService.UserFirstName || " ",
                saveOnly: isSaveOnly,
                bodyPart: bodyPart,
                spi: undefined, /// this.SpiSvg, // svg,
                woundId: woundId,
                responseId: questionnaireResponseId,
                assessmentId: patient.latestAssessment
                    ? patient.latestAssessment.id
                    : "",
                report: localName,
                patientId: patient.id,
                ward: patient.ward,
                room: patient.room,
                bed: patient.bed,
                insuranceName: patient.insuranceName,
                insuranceNumber: patient.insuranceNumber,
                isMultiPrint: isMultiPrint,
                diagnosis: patient.diagnosis,
                department: patient.department,
                departmentIdentifier: patient.departmentIdentifier,
                careLevelString: "",
                careLevel: -1,
                endPoint: FhirService.Endpoint,
                encounterId: patient.encounterId,
                hash: FhirService.Hash,
                fhirServer: FhirService.Endpoint,
                width: -1,
                height: -2,
            };

            if (typeof beforeSend === "function") {
                let r = beforeSend(reportRequestContent);
                if (r) reportRequestContent = r;
            }

            console.debug(
                `Report would be printed with following data as "${targetMethod}":`,
                reportRequestContent,
            );
            window.setTimeout(() => {
                if (Math.random() < 0.5) {
                    resolve(true);
                } else {
                    reject(
                        "Error " +
                            new Date().valueOf() +
                            ": sample Errormessage generated @" +
                            moment(new Date()).format(
                                RuntimeInfo.DateTimeFormat,
                            ) +
                            " imagine an Error which may occur.",
                    );
                }
            }, 4000);
        });
    }

    public static async FetchDownloadResources(
        patient: PatientItem,
    ): Promise<string> {
        const dlResources = await Fhir.Rest.Fetch(
            `Encounter/?_id=${patient.encounterId}&_include=Encounter:subject&_revinclude=RiskAssessment:encounter&_include=Encounter:location`,
        );
        const bundleItems = [];
        bundleItems.push(
            ...PatientItem.LastLoadedPatient.questionnaireResponses,
        );
        bundleItems.push(PatientItem.LastLoadedPatient.flags);
        bundleItems.push(...dlResources);

        for (const item of bundleItems) {
            delete item["_sortField"];
            delete item["_edit"];
            for (const s of Object.keys(item)) {
                if (s.indexOf("_") == 0) {
                    delete item[s];
                }
            }

            if (item.resourceType === "QuestionnaireResponse") {
                const response = item;
                if (
                    response.author == null ||
                    typeof response.author === "undefined"
                ) {
                    response.author = {
                        reference:
                            "Practitioner/" + UserService.Practitioner.id,
                    };
                }

                if (!response.author.display && response.author.reference) {
                    if (
                        response.author.reference.indexOf(
                            UserService.Practitioner.id,
                        ) === -1
                    ) {
                        // download only if the referenced practitioner is not the current user
                        try {
                            const prac = await Fhir.Rest.Get(
                                response.author.reference,
                            );
                            if (prac) {
                                const name = UserService.GetNameObject(prac);
                                if (name) {
                                    response.author.display = [
                                        name.family,
                                        name.given?.join(" "),
                                    ].join(", ");
                                }
                            }
                        } catch (e) {
                            console.warn(e);
                        }
                    } else {
                        // the referenced practitioner is the current user, so simply build the display from the userinfo
                        response.author.display = [
                            UserService.UserLastName,
                            UserService.UserFirstName,
                        ].join(", ");
                    }
                }
            }
        }

        return JSON.stringify(Fhir.Rest.GetBundle(bundleItems, HTTPVerb.put));
    }

    public static PrintProx(
        targetMethod: string,
        questionnaireResponseId: string,
        reportName: string,
        patient: PatientItem,
        isSaveOnly: boolean,
        isMultiPrint: boolean = false,
        bodyPart: string,
        woundId: string = undefined,
        beforeSend?: Function,
    ): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (!ReportService.ReportServer) return resolve(true);
            try {
                await ReportService.Fetch();
                let localName = await this.GetReportByName(reportName);

                if (localName) {
                    RuntimeInfo.PrintFrame.innerHTML = "";
                    RuntimeInfo.PrintFrame.scrollTop = 0;
                    let $body = $(".print-dialog-body");
                    let url = `${NitTools.ExcludeTrailingSlash(ReportService.ReportServer)}/api/${NitTools.ExcludeTrailingSlash(targetMethod)}`;
                    const spiImage = await SpiSpiderSvg.Create(
                        PatientItem.LastLoadedPatient,
                        App.i18n,
                    );
                    let svg: any =
                        spiImage && spiImage.svgImage ? spiImage.svgImage : "";
                    if (typeof svg !== "string" && svg.outerHTML) {
                        svg = svg.outerHTML.trim();
                    }

                    let postObject = {
                        report: localName,
                        responseId: questionnaireResponseId,
                        w: $body.width(),
                        h: $body.height(),
                        endpoint: FhirService.Endpoint,
                        practitionerId:
                            sessionStorage.getItem("PractitionerId") || "",
                        bodyPart: bodyPart || "",
                        spiImage: svg || "",
                        patientId: patient.caseId,
                        saveOnly: isSaveOnly,
                        encounterId: patient.encounterId,
                        isMultiPrint: isMultiPrint,
                        hash: FhirService.Hash,
                        endPoint: FhirService.Endpoint,
                        responsesJson:
                            await this.FetchDownloadResources(patient),
                        assessmentName: patient.assessmentName,
                        anamnesisName: patient.anamnesisName,
                        flags: patient.flags,
                        careLevel: patient.careLevel,
                        careLevelString: patient.careLevelString,
                    };

                    if (typeof beforeSend === "function") {
                        let r = beforeSend(postObject);
                        if (r) postObject = r;
                    }

                    let httpResult = await new HttpClient().post(
                        url,
                        postObject,
                    );
                    let js = JSON.parse(httpResult.response);
                    if (js.success) {
                        if (!isSaveOnly && js.isImagesResult) {
                            if (js.images) {
                                let html = js.html;
                                /* js.images.forEach(img => {
                                    html += `<div class="image-parent"><img src="data:image/gif;base64,${img}" /></div>\n`;
                                }); */

                                RuntimeInfo.PrintDialog.style.display = "block";
                                RuntimeInfo.PrintFrame.innerHTML = html;
                                RuntimeInfo.PrintFrame.scrollTop = 0;
                                RuntimeInfo.PrintFrame.setAttribute(
                                    "data-clear-url",
                                    js.clearUrl,
                                );
                                RuntimeInfo.PrintFrame.setAttribute(
                                    "data-pdf-url",
                                    js.pdfUrl,
                                );
                                RuntimeInfo.PrintFrame.setAttribute(
                                    "data-pdf-id",
                                    js.pdfUid,
                                );
                            }

                            resolve(true);

                            $("[ref='printButton']").show();
                        } else resolve(js);
                    } else {
                        console.warn(
                            "Error when processing report on the server,",
                            js,
                        );
                        if (js.error) {
                            console.warn(js.error);
                        } else {
                            console.warn(JSON.stringify(js));
                        }

                        reject(js.error || JSON.stringify(js));
                    }
                } else {
                    let s = translations.translate("error_report_not_found");
                    s = s.replace(/%FORMNAME%/gi, "");
                    s = s.replace("%REPORT%", reportName);

                    reject(s);
                }
            } catch (ex) {
                const msg = ex.message || ex;
                console.warn(msg);
                reject(msg);
            }
        });
    }

    public static Fetch(): Promise<IReportList> {
        return new Promise<IReportList>((resolve, reject) => {
            if (!ReportService.ReportServer) {
                return resolve(undefined);
            }

            if (this._reportList?.items && this._reportList.items.length > 0) {
                resolve(this._reportList);
                return;
            }

            let client = new HttpClient();
            client
                .configure((o) => o.withTimeout(5000))
                .createRequest(`Report`)
                .withBaseUrl(
                    NitTools.ExcludeTrailingSlash(ReportService.ReportServer) +
                        "/api",
                )
                .asGet()
                .send()
                .catch((error) => {
                    console.warn(error);
                    return reject(error);
                })
                .then((result: HttpResponseMessage) => {
                    if (result) {
                        let tmp = JSON.parse(result.response || result.content);

                        this._reportList = {
                            items: tmp,
                        };

                        for (
                            let i = 0;
                            i < this._reportList.items.length;
                            i++
                        ) {
                            let fileName =
                                ReportService._reportList.items[
                                    i
                                ].toUpperCase();
                            if (fileName.indexOf(".FRX") === -1) {
                                fileName += ".FRX";
                            }

                            ReportService._reportList.items[i] = fileName;
                        }

                        resolve(this._reportList);
                    } else {
                        reject(
                            "Got no result from " +
                                NitTools.ExcludeTrailingSlash(
                                    ReportService.ReportServer,
                                ) +
                                "/api/Report",
                        );
                    }
                });
        });
    }

    public static Preview(
        responseId: string,
        report: string,
        bodyPart?: string,
        openPdf?: boolean,
    ) {
        const loader = <HTMLImageElement>(document.getElementById("reportLoadingGif"));
        if (loader) loader.style.display = "block";

        let rq: Function = window.requestAnimationFrame || window["webkitRequestAnimationFrame"];
        if (!rq) {
            rq = (cb: any) => {
                window.setTimeout(() => cb, 200);
            };
        }

        const dlg = <HTMLDivElement>document.querySelector("#print_dialog");
        if (openPdf) 
            RuntimeInfo.IsLoading = true;
        else 
            dlg.style.display = "block";

        rq(() => {
            let printBody = document.querySelector("#print_body");
            if (printBody) {
                let w = printBody.clientWidth - 20;
                let h = printBody.clientHeight - 26;
                let src = `${report}|${responseId}|${w}|${h}|${sessionStorage.getItem("PractitionerId") || "no_practitioner_found"}|${bodyPart || ""}`;

                if (ConfigService.Debug)
                    console.debug("Concatenated url source: " + src);

                let fragment = btoa(src);

                const url = `${NitTools.ExcludeTrailingSlash(ReportService.PreviewUrl)}/post/${fragment}`;

                App.lastPrintPreviewId = fragment;

                printBody.innerHTML = `<img id="reportLoadingGif" style="position:absolute; left: 50%; top: 2em; margin-left: -2em; width: 2em; height: 2em;" src="images/loading.gif">`;

                let client = new HttpClient();
                SpiSpiderSvg.Create(
                    PatientItem.LastLoadedPatient,
                    App.i18n,
                ).then(async (spiImage) => {
                    // don't use spiImage.svgImage.outerHTML - could result in empty TSPAN elements. instead use the xml-serializer
                    const str = new XMLSerializer().serializeToString(
                        spiImage.svgImage,
                    );
                    PatientItem.LastLoadedPatient.getAssessmentName();
                    const postObject = {
                        report: report,
                        responseId: responseId,
                        w: w,
                        h: h,
                        endpoint: FhirService.Endpoint,
                        practitionerId:
                            sessionStorage.getItem("PractitionerId") || "",
                        bodyPart: bodyPart || "",
                        spiImage: str,
                        patientId: PatientItem.LastLoadedPatient.id,
                        saveOnly: false,
                        encounterId: PatientItem.LastLoadedPatient.encounterId,
                        isMultiPrint: false,
                        hash: FhirService.Hash,
                        endPoint: FhirService.Endpoint,
                        responsesJson: await this.FetchDownloadResources(
                            PatientItem.LastLoadedPatient,
                        ),
                        assessmentName:
                            PatientItem.LastLoadedPatient.assessmentName,
                        anamnesisName:
                            PatientItem.LastLoadedPatient.anamnesisName,
                    };

                    // TODO: Save the generated SPI-Image somewhere... maybe in media?

                    client
                        .createRequest(url)
                        .withContent(postObject)
                        .withTimeout(1000 * 60 * 5)
                        .asPost()
                        .send()
                        .then((result) => {
                            const pdfResult = document.createElement("div");
                            pdfResult.id = "pdfResult";
                            printBody.innerHTML = "";
                            printBody.appendChild(pdfResult);
                            pdfResult.innerHTML = result.response;

                            const page1 =
                                document.querySelector('img[data-page="1"]');
                            if (page1) page1.classList.add("in");
                            let href = `${NitTools.ExcludeTrailingSlash(ReportService.ReportServer)}/api/pdf/`;
                            if (
                                ReportService.ReportServer.indexOf("/api") > -1
                            ) {
                                href = `${NitTools.ExcludeTrailingSlash(ReportService.ReportServer)}/pdf/`;
                            }

                            href += btoa(
                                `${postObject.report}|${postObject.responseId}`,
                            );

                            if (openPdf) {
                                dlg.style.display = "none";
                                const newWin = window.open(
                                    href,
                                    "reportPreview",
                                    "menubar=no,toolbar=no,location=no,status=no,width=" +
                                        screen.width +
                                        ",height=" +
                                        screen.height +
                                        ",top=0,left=0",
                                );
                                if (
                                    !newWin ||
                                    newWin.closed ||
                                    typeof newWin.closed == "undefined"
                                ) {
                                    alert(App.i18n.tr("popups_blocked"));
                                } else if (newWin && newWin.focus) {
                                    newWin.focus();
                                }
                            } else {
                                const reportPrintButton =
                                    document.querySelector(
                                        'a.button.print[target="_pdf"]',
                                    );
                                if (reportPrintButton) {
                                    reportPrintButton.setAttribute(
                                        "href",
                                        href,
                                    );
                                }

                                if (window["initReport"])
                                    window["initReport"]();
                            }
                        })
                        .catch((err) => {
                            printBody.innerHTML =
                                err.message || JSON.stringify(err);
                        })
                        .finally(() => {
                            RuntimeInfo.IsLoading = false;
                        });
                });
            }
        });
    }

    public static Pdf(responseId: string, report: string, bodyPart?: string) {
        let src = `${report}|${responseId}|0|0|${(UserService.Practitioner ? UserService.Practitioner.id : sessionStorage.getItem("PractitionerId")) || "no_practitioner_found"}|${bodyPart || ""}`;
        let url = `${NitTools.IncludeTrailingSlash(ReportService.PreviewUrl)}pdf/${btoa(src)}`;
        window.open(
            url,
            "_print",
            `directories=no, fullscreen=no, height=${document.body.clientHeight}, width=${document.body.clientWidth}, location=no, menubar=no, status=no, toolbar=no`,
        );
    }

    public getReportByName(reportName: string) {
        return new Promise<string>(async (resolve, reject) => {
            try {
                let result = await ReportService.GetReportByName(reportName);
                return resolve(result);
            } catch (e) {
                return reject(
                    `Error when getting report "${reportName}" ` +
                        (e.message || e),
                );
            }
        });
    }

    public fetch(): Promise<IReportList> {
        return new Promise<IReportList>(async (resolve, reject) => {
            try {
                await ReportService.Fetch();
                return resolve(ReportService._reportList);
            } catch (e) {
                return reject(
                    "Error when fetching reports " + (e.message || e),
                );
            }
        });
    }
}

export interface IReportList {
    items: string[];
}

export interface IPrintDiagnosis {
    code: string;
    rank: number;
    display: string;
}

export interface ICheckReportResult {
    fulfilled: boolean;
    message?: ICheckReportResultMessage[];
}

export interface ICheckReportResultMessage {
    text: string;
    questionnaire?: string;
}
